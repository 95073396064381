export default {
	namespaced: true,
	state: () => ({
		group: {},
		leaveGroupModal: false,
		groupTarget: ''
	}),
	mutations: {	
		setAttribute (state, payload){
			state[Object.keys(payload)] = payload[Object.keys(payload)];
		},
		// setObjAttribute (state,payload){
		// 	state[Object.keys(payload)][Object.keys(payload[Object.keys(payload)])] = payload[Object.keys(payload)][Object.keys(payload[Object.keys(payload)])];
		// },
		processPayload (state, {modalTarget, groupTarget, group}){
			state.groupTarget = groupTarget;
			if (modalTarget) state[modalTarget] = !state[modalTarget];
			if (group) state.group = group;
		},
	},
}