export default {
	namespaced: true,
	state: () => ({
		imageData: [],
		galleryErrorMessage: '',
	}),
	mutations: {
		initializeImageData (state, payload){
			state.imageData = payload.images
		},
		addImage (state, payload){
			state.imageData.unshift(payload);
		},
		setImageGalleryErrorMessage (state, payload){
			state.galleryErrorMessage = payload.message;
		},
	},
	actions: {
		initializeImageData ({commit, state, rootState}){
			if (!rootState.common.loggedIn) return;
			axios({
				method: "GET",
				url: "/api/image/category/general",
			})
			.then(function(response){
				commit('initializeImageData', {images: response.data.images || []});
				commit('setImageGalleryErrorMessage', {message: ''});
			})
			.catch(function(err){
				commit('setImageGalleryErrorMessage', {message: err.response.data.message});
			});
		}
	},
	getters: {
		galleryImages: (state, getters, rootState) => {
			// Add a blank image for display purposes when the last row only has two so the images don't go to either side of the div.
			if (state.imageData && state.imageData.length % 3 == 2){
				return [
					...state.imageData, 
					{
						id: 0,
						location: "dummyLocation"
					}
				];
			} else {
				return state.imageData;
			}
		}
	}
};