const structuredResponse = function(status, message, additionalAttributes){
	/*****
	Create a standard object to return for function calls (especially those that may return to the API)  
	Inputs:
			status (int): the HTTP status to return
			message (string): a message to accompany the response
			additionalAttributes (obj): any additional attributes that need to be passed
	Outputs:
			responseObj: the structured response object with additionalAttributes
	*****/
	let responseObj = {'status': status, 'message': message};
	return {...responseObj, ...additionalAttributes};
}

module.exports.structuredResponse = structuredResponse;

module.exports.cloneObj = function(obj){
	/*****
	Create a clone of an object (so as not to alter it by reference)  
	Inputs:
			obj (object): the object to be cloned
	Outputs:
			objClone (object): the clone of the object
	*****/
	const objClone = Object.assign({}, obj);
	return objClone;
}

module.exports.trimRichTextWhitespace = function(inputString){
	/*****
	Remove rich text editor trailing whitespace. The rich text editor handles whitespace
	in a specific way, so this is used to clear it. A more complex/complete regex could
	be written to also trim deliberate user attempts to add whitespace with something like
	<p> tags, etc.

	If a non-string input is supplied, the input is simply returned (for safety).
	Inputs:
			inputString (string): the (rich text editor sourced) string
	Outputs:
			the string with the trailing whitespace removed (or the original input, if not a string)
	*****/
	if (typeof(inputString) == 'string')
		return inputString.replace(/((<div>(\s|&nbsp;|<br>)+<\/div>)|(\s|&nbsp;|<div><br><\/div>|<div><\/div>|<br>))+$/g,'').trim();
	else
		return inputString;
}

module.exports.checkForBlanks = function(dataObj, errMsg="All required fields must be filled in", customErrFn){
	/*********
	Check that there are no blank values being submitted. Note that this checks strings and numbers only.
	Inputs:
			dataObj (obj): JSON object to be checked for blank values
			errMsg (string): error message to accompany any blank values
	Outputs:
			Throws an array of structuredResponses if any field is blank
	**********/
	const errors = [];
	Object.keys(dataObj).forEach((key) => {
		if (customErrFn) errMsg = customErrFn(key);
		switch (typeof(dataObj[key])){
			case 'string':
				if (dataObj[key].trim().length == 0) errors.push(structuredResponse(403, errMsg, {errorField: key}));
				break;
			case 'number':
				if (!dataObj[key] && dataObj[key] !== 0) errors.push(structuredResponse(403, errMsg, {errorField: key}));
				break;
			case 'undefined':
			case 'null':
				errors.push(structuredResponse(403, errMsg, {errorField: key}));
		}
	});
	if (errors.length) throw errors;
	else return dataObj;
}

module.exports.checkMaxStringLength = function(data, errMsg="Fields cannot be longer than 255 characters", limit=255, customErrFn){
	/*********
	Check that there are no string values being submitted that are too long
	Inputs:
			data (string or obj): string or JSON object to be checked for values larger than maxLength
			errMsg (string): error message to accompany any values that are too long
			limit (integer or obj): integer - the maximum length a string value in the data can be
									object - an object containing one or more keys corresponding to the keys in
											 the data (obj) and with associated values that are objects with keys:
											 	maxLength (int): the length limit for that field
											 	friendlyName (string, optional): a user-friendly name to be used  
																				 in the error message 
			customErrFn (fn): a function that takes in the maxLength and (optionally) friendlyName values from the 
							  limit object and returns the error message string associated to the corresponding field
	Outputs:
			Throws an array of structuredResponses if any field is longer than the maxLength, otherwise returns the
			input data (string or obj) after applying trim().
	**********/
	const errors = [];
	if (typeof(data) == 'string'){
		data = data.trim();
		let limitValue;
		if (typeof(limit) == 'number'){
			limitValue = limit;
		} else if (typeof(limit) == 'object') {
			if (customErrFn && limit.maxLength !== undefined) errMsg = customErrFn(limit.maxLength, limit.friendlyName);
			limitValue = limit.maxLength;
		}
		if (data.length > limitValue) errors.push(structuredResponse(413, errMsg));
	} else if (typeof(data) == 'object'){
		if (typeof(limit) == 'number') {
			Object.keys(data).forEach(key => {
				if (typeof(data[key]) === 'string'){
					data[key] = data[key].trim();
					if (data[key].length > limit)
						errors.push(structuredResponse(413, errMsg, {errorField: key}));
				}
			});
		} else if (typeof(limit) == 'object'){
			Object.keys(data).forEach(key => {
				if (customErrFn && limit[key]){
					errMsg = customErrFn(limit[key].maxLength, limit[key].friendlyName);
				} else if (limit[key]){
					errMsg = `The ${key} field cannot be longer than ${limit[key].maxLength} characters.`
				}
				if (typeof(data[key]) === 'string'){
					data[key] = data[key].trim();
					if (limit[key] && data[key].length > limit[key].maxLength)
						errors.push(structuredResponse(413, errMsg, {errorField: key}));
				}
			});
		}
	}
	if (errors.length) throw errors;
	else return data;
}

module.exports.getOnlyDesiredPropsFromObj = function(obj, arr){
	/*****
	Pull out only the desired fields from an object
	adapted from https://stackoverflow.com/questions/60620283/destructure-object-properties-from-properties-provided-in-an-array
	Inputs:
			obj (object): object whose contents are being extracted
			arr (array): array of strings corresponding to the object keys to be extracted
	Outputs:
			An object containing only the keys specified in arr
	*****/
	return Object.assign({}, ...arr.map(a => ({[a]: obj[a]})));
}

module.exports.getTicketTypeFromEvent = function(ticketId, eventData) {
	/*****
	Return the ticket object with the matching ID.
	Inputs: 
			ticketId (int): the desired ticket's (est's) ID.
			eventData (obj): the event to extract the ticket from
	Outputs:
			the matching ticket, if one is found, or undefined
	******/
	if (ticketId && eventData.id){
		return eventData.eventSubscriptionTypes[eventData.eventSubscriptionTypes.findIndex((est)=>{
			return est.id == ticketId;
		})];
	} else {
		return {};
	}

}