import Vue from 'vue';
import Vuex from 'vuex';
import common from './common';
import loginStore from './login';
import eventCreationStore from './eventCreation';
import imageGalleryStore from './imageGallery';
import bankInfoStore from './bankInfo';
import addressInfoStore from './addressInfo';
import eventDropdownOptionsStore from './eventDropdownOptions';
import groupDropdownOptionsStore from './groupDropdownOptions';
import checkout from './checkout';

Vue.use(Vuex);

const store = new Vuex.Store({
	modules: {
		common,
		loginStore,
		imageGalleryStore,
		eventCreationStore,
		bankInfoStore,
		addressInfoStore,
		eventDropdownOptionsStore,
		groupDropdownOptionsStore,
		checkout,
	},
});

export default store;