import { getOnlyDesiredPropsFromObj } from 'sharedFrontendBackend/utils.js';

const generateTicketObj = ()=> {
	return {
		id: null,
		name: '',
		description: '',
		price: null,
		maxAmount: null,
		question: '',
	}
}

const generateEventDataObj = ()=> {
	return {
		id: null,
		eventLink: null,
		eventType: '',
		eventName: '',
		eventShortDescription: '',
		eventLongDescription: '',
		isPrivate: false,
		eventImage: '',
		eventDate: '',
		eventTime: '',
		campaignEndDate: '',
		campaignEndTime: '',
		eventTimeZone: '',
		online: false,
		eventLocation: '',
		eventCity: '',
		eventState: '',
		eventGoal: null,
		// eventGoalType: '',
		continueSellingTickets: false,
		maxAttendance: null,
		paymentMethod: '',
		eventSubscriptionTypes: [
			generateTicketObj()
		],
		rrule: '',
		maxTicketsPerAccount: null,
		groupAssociationAtCreation: '', // use empty string so default option is selected in dropdown
	}
}

const setAttribute = (state, payload) => {
	state[Object.keys(payload)] = payload[Object.keys(payload)];
};

const setTicketAttribute = (state, payload) => {
	state.eventSubscriptionTypes[payload.index][payload.key] = payload.value;
};

const addTicketObj = (state) => {
	state.eventSubscriptionTypes.push(
		generateTicketObj()
	);
};

const removeTicketObj = (state, payload) => {
	if (state.eventSubscriptionTypes.length > 1)
		state.eventSubscriptionTypes.splice(payload.index,1);
};

// Set defaults for new event creation
const eventCreationStoreState = generateEventDataObj();
eventCreationStoreState.eventType = 'standard';
eventCreationStoreState.eventLocation = 'TBD';
eventCreationStoreState.paymentMethod = 'transfer';
eventCreationStoreState.eventSubscriptionTypes[0].name = 'Basic Ticket';
eventCreationStoreState.eventSubscriptionTypes[0].description = 'Event Ticket';
const date = new Date();
const days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
eventCreationStoreState.eventDate = days[date.getDay()] + " " + (date.getMonth()+1) + "/" + date.getDate() + "/" + date.getFullYear();
// eventCreationStoreState.campaignEndDate = days[date.getDay()] + " " + (date.getMonth()+1) + "/" + date.getDate() + "/" + date.getFullYear();
eventCreationStoreState.eventTime = '12:00 pm';
// eventCreationStoreState.campaignEndTime = '12:00 pm';

export default {
	namespaced: true,
	state: () => (
		eventCreationStoreState
	),
	mutations: {
		populateDraftEventData (state, payload){
			// Break out payload to handle individual cases
			const {eventDate, eventSubscriptionTypes : ests, eventRrule: rrule, ...remainingEventProps } = payload.eventData;

			// Get only desired properties of (ticket) object and replace the default eventSubscriptionTypes
			// const selectAttributes = ({id, name, description, maxAmount, price, question}) => ({id, name, description, maxAmount, price, question});
			if (ests && ests.length && ests[0].id){
				state.eventSubscriptionTypes = ests.map(est => {
					return getOnlyDesiredPropsFromObj(est, ['id', 'name', 'description', 'maxAmount', 'price', 'question']);
				});
			}

			// Special handling for rrule because the naming convention isn't (yet) aligned
			state.rrule = rrule || '';

			// Event date/time are stored in differently named variables for user entry as compared to the db
			state.eventDate = remainingEventProps.eventDateDisplay || '';
			state.eventTime = remainingEventProps.eventTimeDisplay || '';
			
			// For everything else, just populate the data straight into state
			Object.keys(remainingEventProps).forEach(key => {
				if (state.hasOwnProperty(key)) state[key] = payload.eventData[key];
				// Special handling to set default for date fields
				if (key == 'eventDate' && !payload.eventData[key]) state[key] = days[date.getDay()] + " " + (date.getMonth()+1) + "/" + date.getDate() + "/" + date.getFullYear();
				// if (key == 'campaignEndDate' && !payload.eventData[key]) state[key] = days[date.getDay()] + " " + (date.getMonth()+1) + "/" + date.getDate() + "/" + date.getFullYear();
			});
		},
		setEventAttribute (state, payload){
			setAttribute(state, payload);
		},
		setEventSubscriptionType (state, payload){
			setTicketAttribute(state, payload);
		},
		addEventSubscriptionType (state){
			addTicketObj(state);
		},
		removeEventSubscriptionType (state, payload){
			removeTicketObj(state, payload);
		},
	},
	actions: {
		addTicket({commit}){
			commit('addEventSubscriptionType');
			commit('error/addEventSubscriptionTypeError');
		},
		removeTicket({commit}, payload){
			commit('removeEventSubscriptionType', payload);
			commit('error/removeEventSubscriptionTypeError', payload);
		},
		loadDraftEvent({commit, dispatch}, payload){
			commit('populateDraftEventData', payload);
			dispatch('error/addEventSubscriptionTypesErrorForDraft', payload);
		}
	},
	getters: {
		freeEvent: (state)=>{
			const totalOfTicketPrices = state.eventSubscriptionTypes.reduce(function(sumOfTicketPrices, { price }){
				// Hack price so that if ticket is not set as free (which sets price to a numeric zero) and it is not
				// filled in (either empty string or null), the event comes back as not free. This allows for correct
				// responsive behavior in other parts of event creation (e.g., showing/validating payment info) 
				if (price === '' || price === null) price = 1;
				return sumOfTicketPrices + Number(price);
			}, 0);
			return !Boolean(totalOfTicketPrices);
		},
		totalTicketsSpecified: (state)=>{
			return state.eventSubscriptionTypes.reduce((ticketTotal, est)=>{
				const ticketNumber = (isNaN(parseInt(est.maxAmount))) ? 0 : parseInt(est.maxAmount);
				return ticketTotal + ticketNumber;
			}, 0);
		}
	},
	modules: {
		error: {
			namespaced: true,
			state: () => (
				generateEventDataObj()
			),
			mutations: {
				resetErrorState (state){
					Object.assign(state, generateEventDataObj());
				},
				setEventAttributeError (state, payload){
					setAttribute(state, payload);
				},
				setEventSubscriptionTypeError (state, payload){
					setTicketAttribute(state, payload);
				},
				addEventSubscriptionTypeError (state){
					addTicketObj(state);
				},
				removeEventSubscriptionTypeError(state, payload){
					removeTicketObj(state, payload);
				},
			},
			actions: {
				clearErrors ({state, commit, dispatch}){
					const estCount = state.eventSubscriptionTypes.length;
					commit('resetErrorState');
					// Add additional tickets in error state as needed (remember, there's one there already)
					for (let i=1; i < estCount; i++) {
						commit('addEventSubscriptionTypeError');
					}
					dispatch('addressInfoStore/error/clearErrors', null, {root:true});
					dispatch('bankInfoStore/error/clearErrors', null, {root:true});
				},
				setErrors({commit}, payloadArray){
					payloadArray.forEach(payload => {
						if (payload.key){ // only tickets have a key prop set
							commit('setEventSubscriptionTypeError', payload);
						} else {
							commit('setEventAttributeError', payload);
						}
					});
				},
				addEventSubscriptionTypesErrorForDraft({commit}, payload){
					const ests = payload.eventData.eventSubscriptionTypes;
					const estCount = (ests && ests[0].id) ? ests.length : 0;
					// Add additional tickets in error state as needed (remember, there's one there already)
					for (let i=1; i < estCount; i++) {
						commit('addEventSubscriptionTypeError');
					}
				},
			},
			getters: {
				errorMap: (state) => {
					// Initialize object to track steps
					const stepMapping = {
						eventName: 1,
						eventType: 10,
						eventShortDescription: 1,
						eventLongDescription: 3,
						isPrivate: 1,
						eventImage: 2,
						eventDate: 5,
						eventTime: 5,
						campaignEndDate: 5,
						campaignEndTime: 5,
						eventTimeZone: 5,
						online: 4,
						eventLocation: 4,
						eventCity: 4,
						eventState: 4,
						eventGoal: 7,
						// eventGoalType: '',
						continueSellingTickets: 7,
						maxAttendance: 7,
						paymentMethod: 8,
						eventSubscriptionTypes: 6,
						rrule: 5,
						maxTicketsPerAccount: 7,
						groupAssociationAtCreation: 9
					}
					const errorSteps = {};
					
					for (let key in state){
						if (key=='eventSubscriptionTypes'){
							state[key].forEach(ticket => {
								for (let prop in ticket){
									if (ticket[prop]) errorSteps[stepMapping[key]] = true;
								}
							})
						} else {
							if (state[key]) errorSteps[stepMapping[key]] = true;
						}
					}
					return errorSteps;
				},
				errorsRemaining: (state, getters) => {
					// const errorMap = getters.errorMap; 
					// if (errorMap && Object.keys(errorMap).length === 0 && errorMap.constructor === Object)

					// If there are no props set in errorMap, there are no errors. No need for complex checks
					// (as commented above), since getters.errorMap is known to us 
					if (Object.keys(getters.errorMap).length === 0)
						return false;
					else
						return true;
				},
			},
		}
	}
};