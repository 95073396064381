import Vue from 'vue';
import store from 'store/mainStore';
import 'es6-promise/auto';
import 'quasar/dist/quasar.umd.min';

// import utils from 'js/services/utils';
// Vue.use(utils);

(function(wv, undefined){
	wv.header = new Vue({
		el: "#headerContainer",
		store: wv.store || store,
		components: {
			'custom-header': () => import(/* webpackPrefetch: true */ 'components/header'),
		},
		// Note: this created should be inside the header.vue component, but the header doesn't re-render if it's there.
		// When this becomes one big Vue app, this can probably go!!!
		created: function(){
			const common = JSON.parse(document.getElementById("headerContainer").getAttribute("data-common"));
			this.$store.commit('common/setState', common);
		},
		data: function(){
			return {
				nonce: 0,
			}
		},
	});
}( window.wv = window.wv || {} ));