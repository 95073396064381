const generateLoginObj = function(over18Value){
	return {
		firstName: '',
		lastName: '',
		email: '',
		password: '',
		passwordAgain: '',
		over18: over18Value,
	}
}

export default {
	namespaced: true,
	state: () => ({
		user: generateLoginObj(false),
		loginModal: false,
		signin: false,
		submitted: false,
		validationErrorMessage: '',
	}),
	mutations: {
		setAttribute (state, payload){
			state[Object.keys(payload)] = payload[Object.keys(payload)];
		},
		setObjAttribute (state,payload){
			state[Object.keys(payload)][Object.keys(payload[Object.keys(payload)])] = payload[Object.keys(payload)][Object.keys(payload[Object.keys(payload)])];
		},
	},
	modules: {
		error: {
			namespaced: true,
			state: () => ({
				user: generateLoginObj(''),
			}),
			mutations: {
				resetErrorState (state){
					Object.assign(state.user, generateLoginObj(''));
				},
				setAttribute (state, payload){
					state[Object.keys(payload)] = payload[Object.keys(payload)];
				},
				setObjAttribute (state, payload){
					state[Object.keys(payload)][Object.keys(payload[Object.keys(payload)])] = payload[Object.keys(payload)][Object.keys(payload[Object.keys(payload)])];
				},
			},
			actions: {
				clearErrors ({commit}){
					commit('resetErrorState');
				},
				setErrors({commit}, payloadArray){
					payloadArray.forEach(payload => {
						commit('setObjAttribute', payload);
					});
				},
			},
		},
	}
}