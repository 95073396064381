export default {
	namespaced: true,
	state: () => ({
		submissionInProgress: false,
		validationErrorMessage: ''
	}),
	mutations: {
		setAttribute (state, payload){
			state[Object.keys(payload)] = payload[Object.keys(payload)];
		},
		setState (state, payload){
			Object.assign(state, payload);
		},
	},
}