export default {
	namespaced: true,
	state: () => ({
		event: {},
		updateEventModal: false,
		massMessageEventModal: false,
		unregisterFromEventModal: false,
		deleteDraftEventModal: false,
		cancelEventModal: false,
		cancelRecurringEventModal: false,
		ticketsForEventModal: false,
		messageHostModal: false,
		eventTarget: ''
	}),
	mutations: {	
		setAttribute (state, payload){
			state[Object.keys(payload)] = payload[Object.keys(payload)];
		},
		// setObjAttribute (state,payload){
		// 	state[Object.keys(payload)][Object.keys(payload[Object.keys(payload)])] = payload[Object.keys(payload)][Object.keys(payload[Object.keys(payload)])];
		// },
		processPayload (state, {modalTarget, eventTarget, duplicateEvent, event}){
			state.eventTarget = eventTarget;
			if (modalTarget) state[modalTarget] = !state[modalTarget];
			if (duplicateEvent) state.duplicateEvent = true;
			if (event) state.event = event;
		},
	},
	// modules: {
	// 	error: {
	// 		namespaced: true,
	// 		state: () => ({
	// 			bankInfo: generateBankInfoObj(),
	// 			userInfo : generateUserInfoObj(),
	// 		}),
	// 		mutations: {
	// 			resetErrorState (state){
	// 				Object.assign(state.bankInfo, generateBankInfoObj());
	// 				Object.assign(state.userInfo, generateUserInfoObj());
	// 			},
	// 			setAttribute (state, payload){
	// 				state[Object.keys(payload)] = payload[Object.keys(payload)];
	// 			},
	// 			setObjAttribute (state,payload){
	// 				state[Object.keys(payload)][Object.keys(payload[Object.keys(payload)])] = payload[Object.keys(payload)][Object.keys(payload[Object.keys(payload)])];
	// 			},
	// 		},
	// 		actions: {
	// 			clearErrors ({commit}){
	// 				commit('resetErrorState');
	// 			},
	// 			setErrors({commit}, payloadArray){
	// 				payloadArray.forEach(payload => {
	// 					commit('setObjAttribute', payload);
	// 				});
	// 			},
	// 		},
	// 		getters: {
	// 			bankAndLegalErrors: (state) => {
	// 				for (let key in state.bankInfo){
	// 					if (state.bankInfo[key]) return true;
	// 				};
	// 				for (let key in state.userInfo){
	// 					if (state.userInfo[key]) return true;
	// 				};
	// 			},
	// 		},
	// 	},
	// },
}