const generateBankInfoObj =  ()=> {
	return {
		accountNumber: '',
		routingNumber: '',
	}
}

const generateUserInfoObj =  ()=> {
	return {
		legalFirstName: '',
		legalLastName: '',
		ssNumber: '',
		dobYear: '',
		dobMonth: '',
		dobDay: '',
	}
}

export default {
	namespaced: true,
	state: () => ({
		bankInfo: generateBankInfoObj(),
		userInfo : generateUserInfoObj(),
		fourDigitBankAcct: '',
		validationErrorMessage: '',
		accountSavedMessage: '',
		submitted: false
	}),
	mutations: {	
		setAttribute (state, payload){
			state[Object.keys(payload)] = payload[Object.keys(payload)];
		},
		setObjAttribute (state,payload){
			state[Object.keys(payload)][Object.keys(payload[Object.keys(payload)])] = payload[Object.keys(payload)][Object.keys(payload[Object.keys(payload)])];
		},
	},
	modules: {
		error: {
			namespaced: true,
			state: () => ({
				bankInfo: generateBankInfoObj(),
				userInfo : generateUserInfoObj(),
			}),
			mutations: {
				resetErrorState (state){
					Object.assign(state.bankInfo, generateBankInfoObj());
					Object.assign(state.userInfo, generateUserInfoObj());
				},
				setAttribute (state, payload){
					state[Object.keys(payload)] = payload[Object.keys(payload)];
				},
				setObjAttribute (state,payload){
					state[Object.keys(payload)][Object.keys(payload[Object.keys(payload)])] = payload[Object.keys(payload)][Object.keys(payload[Object.keys(payload)])];
				},
			},
			actions: {
				clearErrors ({commit}){
					commit('resetErrorState');
				},
				setErrors({commit}, payloadArray){
					payloadArray.forEach(payload => {
						commit('setObjAttribute', payload);
					});
				},
			},
			getters: {
				bankAndLegalErrors: (state) => {
					for (let key in state.bankInfo){
						if (state.bankInfo[key]) return true;
					};
					for (let key in state.userInfo){
						if (state.userInfo[key]) return true;
					};
				},
			},
		},
	},
}