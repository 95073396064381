const generateAddressObj = ()=> {
	return {
		payStreet: '',
		payCity: '',
		payState: '',
		payZipcode: '',
	}
}

export default {
	namespaced: true,
	state: () => ({
		addressOnFile: generateAddressObj(),
		address: generateAddressObj(),
		hasSavedAddress: false,
		useSavedAddress: false,
		addressSavedMessage: '',
		addressSubmitted: false,
		validationErrorMessage: '',
	}),
	mutations: {	
		setAttribute (state, payload){
			state[Object.keys(payload)] = payload[Object.keys(payload)];
		},
		setObjAttribute (state,payload){
			state[Object.keys(payload)][Object.keys(payload[Object.keys(payload)])] = payload[Object.keys(payload)][Object.keys(payload[Object.keys(payload)])];
		},
	},
	modules: {
		error: {
			namespaced: true,
			state: () => ({
				address: generateAddressObj()
			}),
			mutations: {
				resetErrorState (state){
					Object.assign(state.address, generateAddressObj());
				},
				setAttribute (state, payload){
					state[Object.keys(payload)] = payload[Object.keys(payload)];
				},
				setObjAttribute (state,payload){
					state[Object.keys(payload)][Object.keys(payload[Object.keys(payload)])] = payload[Object.keys(payload)][Object.keys(payload[Object.keys(payload)])];
				},
			},
			actions: {
				clearErrors ({commit}){
					commit('resetErrorState');
				},
				setErrors({commit}, payloadArray){
					payloadArray.forEach(payload => {
						commit('setObjAttribute', payload);
					});
				},
			},
			getters: {
				addressErrors: (state) => {
					for (let key in state.address){
						if (state.address[key]) return true;
					}
				},
			},
		},
	},
}