export default {
	namespaced: true,
	state: () => ({
		baseUrl: '',
		loggedIn: false,
		userFullName: '',
	}),
	mutations: {
		setAttribute (state, payload){
			state[Object.keys(payload)] = payload[Object.keys(payload)];
		},
		setState (state, payload){
			Object.assign(state, payload);
		},
	},
}